import React from 'react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
  DefaultNamespace,
  initReactI18next,
  KeyPrefix,
  Namespace,
  useTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';

// namespaces
import common from '../public/locales/en/common.json';

export function renderHtml(html: string) {
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
}

/**
 * Main translation hook
 */
export function useTranslationWrap<
  N extends Namespace = DefaultNamespace<'common'>,
  TKPrefix extends KeyPrefix<N> = undefined,
>(
  ns?: N | Readonly<N>,
  options?: UseTranslationOptions<TKPrefix>,
): UseTranslationResponse<N, TKPrefix> {
  const { t, ready, ...other } = useTranslation(ns, options);

  if (!ready) {
    const notReadyT = () => '...';
    // @ts-ignore
    return { t: notReadyT, ready, ...other };
  }

  return { t, ready, ...other };
}

declare module 'react-i18next' {
  interface CustomTypeOptions {
    resources: {
      common: typeof common;
    };
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
