/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Box, CircularProgress, SxProps, Theme,
} from '@mui/material';
import { colors } from '~theme/index';

/**
 * Component for rendering circular loader.
 */
function CircularLoaderFullHeight({ text, sx = {} }: { text?: string, sx?: SxProps<Theme> }) {
  return (
    <Box sx={{
      display: 'flex',
      height: '100vh',
      backgroundColor: colors.paper,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      ...sx,
    }}
    >
      <CircularProgress />
      {text ? (
        <Box sx={{
          color: colors.orange,
          fontSize: '16px',
          position: 'absolute',
          top: 'calc(50vh + 35px)',
        }}
        >
          {text}
        </Box>
      ) : ''}
    </Box>
  );
}
export default CircularLoaderFullHeight;
